<template>
  <List item-layout="vertical">
    <ListItem v-model="userInfo">
      <ListItemMeta :avatar="userInfo.profilePhoto" :title="userInfo.realName" :description="userInfo.loginName" />
      {{ userInfo.content }}
    </ListItem>
  </List>
</template>

<script>
export default {
  name: "userDetail",
  data() {
    return {
      userInfo: {},
      data: [
        {
          title: "This is title 1",
          description:
            "This is description, this is description, this is description.",
          avatar:
            "https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar",
          content:
            "This is the content, this is the content, this is the content, this is the content.",
        },
      ],
    };
  },
  created() {
    // 判断是编辑还是新建
    this._initialization();
    this.userInfo = this.$route.query.userInfo;
  },
  mounted() {
    console.log(this.$route);
    window.addEventListener(
      "resize",
      () => this.setTags(window.innerWidth),
      false
    );
  },
  methods: {
    // 设置面包屑
    crumbstring(str, len) {
      let setValue = {
        lenCtrl: len,
        addValue: str,
      };
      this.$store.dispatch("setBreadcrumb", setValue);
    },
    _initialization() {
      let a = JSON.parse(sessionStorage.getItem("pageStr"));
      this.crumbstring(["用户管理"], 0);
      this.crumbstring(["个人用户"], 1);
      this.crumbstring(["用户详情"], 2);
    },
    setTags(width) {
      if (width >= 1680) {
        this.maxTags = 3;
      } else if (width >= 1440) {
        this.maxTags = 2;
      } else {
        this.maxTags = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.demo-split {
  height: 200px;
  border: 1px solid #dcdee2;
}
.demo-split-pane {
  padding: 10px;
}
</style>
